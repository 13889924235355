.preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  overflow-y: auto;
  font-size: 14px;
  color: #444;

  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-frame-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.preview-frame-cover {
  border: 20px solid #fff;
  border-radius: 64px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.preview-frame-cover:before,
.preview-frame-cover:after {
  content: '';
  position: absolute;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.preview-frame-cover:before {
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border-radius: 64px;
}

.preview-frame-cover:after {
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 46px;
}

.preview-frame-border {
  transform-origin: 0 0;
  background: #fff;
  padding: 90px 20px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px,
    inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.preview-frame-border.iphonex {
  padding: 20px;
  border-radius: 64px;
  position: relative;
}

.preview-frame-border.iphonex:before {
  content: url('./iphonex-notch.svg');
  position: absolute;
  left: 50%;
  margin-left: -110px;
  top: 17px;
  z-index: 10000;
}

.preview-frame-border.iphonex:after {
  content: '';
  display: block;
  height: 5px;
  width: 134px;
  background: #000;
  border-radius: 3px;
  position: absolute;
  bottom: 34px;
  left: 50%;
  margin-left: -67px;
  z-index: 10000;
}

.preview-frame-clip {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.preview-frame-border.iphonex .preview-frame-clip {
  border-radius: 44px;
}

.preview-frame {
  box-sizing: content-box;
  border: none;
}

.preview-close {
  display: block;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

.preview-close .icon {
  background: #fff;
  width: 40px;
  height: 40px;
  mask-size: contain;
  display: block;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.preview-close:hover .icon {
  opacity: 1;
}

.preview-size-menu {
  position: fixed;
  right: 30px;
  top: 34px;
  width: 200px;
}

.preview-size-menu-inline {
  position: static;
}

.preview-app-icon {
  width: 96px;
  height: 96px;
  border-radius: 17.5%;
  display: inline-block;
}

.preview-app-icon-wrapper {
  display: block;
  text-align: center;
  margin-right: 16px;
}

.responsive-preview-frame {
  display: flex;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  border: none;
  overflow: hidden;
}

.responsive-preview-frame--desktop {
  display: flex;
  height: 100%;
  bottom: 0;
}

.responsive-preview-frame--mobile {
  border-radius: 2rem;
  width: calc(100% - 44px);
  height: calc(100% - 28px);
  margin-left: 22px;
  margin-top: 18px;
  margin-right: 10px;
  margin-bottom: 0;
}

.responsive-preview-frame--tablet {
  width: calc(100% - 78px);
  height: calc(100% - 90px);
  margin: 0;
  margin-top: 40px;
  margin-left: 40px;
}

.responsive-preview-frame-wrapper .device-frame {
  z-index: 1;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-preview-frame-wrapper.desktop {
  width: 100%;
  border: none;
  border-radius: 0;
}

.preview-app-icon-wrapper img {
  display: block;
}

.preview-proton-attribution {
  position: fixed;
  bottom: 32px;
  left: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  text-decoration: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 24px;
  color: black;

  transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

.preview-proton-attribution:hover {
  background-color: rgba(0, 168, 152, 1);
  border-color: rgba(0, 168, 152, 1);
  color: white;
}

.preview-proton-attribution h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.preview-proton-attribution .logo {
  display: inline-block;
  background-image: url('./adalo-circle-logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 45px;
  width: 72px;
  margin-left: -20px;
}

.preview-proton-attribution .logo-white {
  mask-image: url('./adalo-circle-logo.svg');
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 26px;
  width: 72px;
  margin-left: 6px;
  margin-bottom: -2px;

  transition: opacity 0.2s ease;
}

.preview-proton-attribution:hover .logo-white {
  opacity: 1;
}

.preview-proton-tagline {
  margin: 7px 0 15px;
  display: block;
  line-height: 1.4em;
}

.top-stripe {
  height: 4px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
}

.web-preview-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
  display: flex;
  padding-top: 4px;
  padding: 4px 20px 0;
  flex-direction: row;
  align-items: center;
}

.web-preview-header--responsive {
  display: flex;
  height: 49px;
  background: #333;
  font-family: 'Work Sans', sans-serif;
  color: #fff;
  justify-content: space-between;
  padding: 0.81rem 0.75rem;
  box-shadow: none;
}

.web-preview-header--responsive .app-name {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .web-preview-header--responsive .app-name {
    display: none;
  }
}

.web-preview-header--responsive > div:not(.device-type-selector) {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.web-renderer-wrapper {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.web-renderer-wrapper--responsive {
  display: flex;
  width: 100vw;
  height: 100%;
  bottom: 0;
  position: fixed;
  padding-top: 49px;
}

.web-renderer-wrapper--responsive.framed {
  align-items: center;
  justify-content: center;
  position: fixed;
}

.web-renderer-wrapper.framed {
  align-items: center;
  justify-content: center;
}

.web-preview-header-spacer {
  flex: 1;
}

.web-preview-header-logo {
  width: 100px;
  background: url('./logo.svg') no-repeat left;
  height: 60px;
}

.device-type-selector {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
}

.device-type-selector.align-center {
  position: absolute;
  right: 0;
  left: 0;
}

.device-type-button {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 0.25rem;
  height: fit-content;
  transition: all 0.1s;
  background-color: transparent;
}

.btn.device-type-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.fullscreen-wrapper {
  justify-content: flex-end;
}

.fullscreen-button {
  display: flex;
  align-items: center;
  padding: 0;
}

.fullscreen-button > img {
  transition: all 0.2s;
  padding: 0.2rem;
}

.fullscreen-button:not(.fullscreen-button-exit):hover > img {
  border-radius: 18.75rem;
  background: rgba(255, 255, 255, 0.2);
}

.fullscreen-button-exit {
  border-radius: 18.75rem;
  background: transparent;
  height: auto;
}

.fullscreen-button-exit > img {
  border-radius: 0 0 4px 4px;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  height: 28px;
}

.fullscreen-button-exit:hover > img {
  background: rgba(0, 0, 0, 0.3);
}

.fullscreen-wrapper--floating {
  position: fixed;
  top: 0;
  right: 0.75rem;
  z-index: 100000;
}

@media (hover: none) {
  .fullscreen-wrapper > button {
    display: none;
  }
}

.fullscreen-enabled .web-renderer-wrapper--responsive {
  height: 100%;
  padding: 0;
}
