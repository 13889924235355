* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100%;
}

body {
  font-family: -apple-system, "Segoe UI", Roboto, Ubuntu, "SF Pro Text", system-ui, "Helvetica Neue", sans-serif;
  overflow: hidden;
  background: #fff;
  user-select: none;
  -webkit-font-smoothing: antialiased;
}

.app {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.standalone {
  height: 100vh;
}

.app-border {
  position: fixed;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid #000;
  z-index: 10000;
  pointer-events: none;
}
