.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.main-wrapper .error-page-wrapper {
  width: 100%;
  height: 100%;
}

.css-view-175oi2r:has(> .css-accessibilityImage-9pa8cd),
.css-175oi2r:has(> .css-9pa8cd) {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}
